import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  TableSortLabel,
  Checkbox,
  FormControlLabel,
  Link
} from '@material-ui/core';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  header: {
    textAlign: 'center',
  },
  yesIcon: {
    color: 'green',
  },
  noIcon: {
    color: 'red',
  }
});

function App() {
  const [data, setData] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);
  const [onlyNonTradable, setOnlyNonTradable] = useState(false);
  const [currentBlock, setCurrentBlock] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.denouementool.com/api/records');
        setData(response.data);
      } catch (error) {
        console.error('There was an error fetching the data!', error);
      }
    };

    const fetchCurrentBlock = async () => {
      try {
        const response = await axios.get('https://api.denouementool.com/api/block_no');
        setCurrentBlock(response.data.current_block);
      } catch (error) {
        console.error('Error fetching current block number:', error);
      }
    };

    fetchData();
    fetchCurrentBlock();
  }, []);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    let sortableItems = [...data];
    if (onlyNonTradable) {
      sortableItems = sortableItems.filter(item => !item.istradeable);
    }
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    sortableItems = sortableItems.filter(item => item.symbol !== "UNI-V2");
    return sortableItems;
  };

  return (
    <div className="App">
      <div style={{ position: 'absolute', top: 0, left: 0, padding: '10px' }}>
        {currentBlock !== null ? (
          <span>Current Block: {currentBlock}</span>
        ) : (
          <span>Loading block number...</span>
        )}
      </div>

      <h1 className={classes.header}>Sniper Counter</h1>
      <FormControlLabel 
        control={
          <Checkbox
            checked={onlyNonTradable}
            onChange={(e) => setOnlyNonTradable(e.target.checked)}
            name="onlyNonTradable"
            color="primary"
          />
        }
        label="Show only non-tradable"
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {['Contract', 'Snipe Count', 'Symbol', 'Is Tradeable', 'Tradeable Time', 'Deployed Block'].map((headCell, index) => (
                <TableCell
                  key={index}
                  sortDirection={sortConfig?.key === headCell.toLowerCase().replace(/ /g, '_') ? sortConfig.direction : false}
                >
                  <TableSortLabel
                    active={sortConfig?.key === headCell.toLowerCase().replace(/ /g, '_')}
                    direction={sortConfig?.key === headCell.toLowerCase().replace(/ /g, '_') ? sortConfig.direction : 'asc'}
                    onClick={() => requestSort(headCell.toLowerCase().replace(/ /g, '_'))}
                  >
                    {headCell}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getSortedData().map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link 
                      href={`https://etherscan.io/address/${item.contract}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      style={{ display: 'flex', alignItems: 'center', color: 'inherit' }}
                  >
                      {item.contract} <LaunchIcon style={{ marginLeft: 5, fontSize: '18px', color: 'inherit' }} />  
                  </Link>
                </TableCell>
                <TableCell>{item.snipe_count}</TableCell>
                <TableCell>{item.symbol}</TableCell>
                <TableCell>
                  {item.istradeable ? 'Yes' : 'No'}
                  {item.istradeable ? 
                    <FiberManualRecord className={classes.yesIcon} style={{ marginLeft: 5, marginBottom: -7 }} /> : 
                    <FiberManualRecord className={classes.noIcon} style={{ marginLeft: 5, marginBottom: -7 }} />}
                </TableCell>
                <TableCell>{item.tradeable_time}</TableCell>
                <TableCell>{item.deployed_block_no}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default App;
